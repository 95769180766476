<template>
  <Layout class="layout">
    <Sider hide-trigger>
      <div class="logo">
        <!-- <i class="iconfont iconLOGO"></i> -->
        企业管理
      </div>
      <Menu :active-name="activeName"
        theme="light"
        width="auto"
        :open-names="openName">
        <!-- <template v-for="item in menuList"></template> -->
        <MenuItem to="/home"
          name="/home"
          v-if="matchMenu(/home/)">
        <!-- <Icon type="ios-navigate"></Icon> -->
        <span class="iconfont iconshouye"></span>
        首页
        </MenuItem>
        <Submenu name="resource"
          v-if="matchMenu(/statistic|efficiency/)">
          <template slot="title">
            <span class="iconfont iconziyuanguanli"></span>
            数据展示
          </template>
          <MenuItem to="/resource/Resource"
            name="/resource/Resource">资源概览</MenuItem>
          <MenuItem v-if="matchMenu(/efficiency/)"
            to="/resource/efficiency"
            name="/resource/efficiency">人效管理</MenuItem>
        </Submenu>
        <Submenu name="project"
          v-if="matchMenu(/project|shoot/)">
          <template slot="title">
            <span class="iconfont iconxiangmuguanli"></span>
            项目管理
          </template>
          <MenuItem v-if="matchMenu(/project/)"
            to="/project/projectList"
            name="/project/projectList">项目列表</MenuItem>
          <MenuItem v-if="matchMenu(/shoot/)"
            to="/project/shootList"
            name="/project/shootList">拍摄列表</MenuItem>
        </Submenu>
        <Submenu name="organization"
          v-if="matchMenu(/member|department|visitor|auth/)">
          <template slot="title">
            <span class="iconfont iconzuzhiguanli"></span>
            组织管理
          </template>
          <MenuItem v-if="matchMenu(/department/)"
            to="/organization/membersDepartments"
            name="/organization/membersDepartments">成员与部门</MenuItem>
          <MenuItem v-if="matchMenu(/member/)"
            to="/organization/externalContacts"
            name="/organization/externalContacts">外部联系人</MenuItem>
          <MenuItem v-if="matchMenu(/visitor/)"
            to="/organization/visitor"
            name="/organization/visitor">访客记录</MenuItem>
          <MenuItem v-if="matchMenu(/auth/)"
            to="/setUp/jurisdiction"
            name="/setUp/jurisdiction">管理员权限</MenuItem>
        </Submenu>

        <!-- <Submenu
          name="expense"
          v-if="matchMenu(/finance|flow/)"
        >
          <template slot="title">
            <span class="iconfont iconfeiyongzhongxin"></span>
            费用中心
          </template>
          <MenuItem
            v-if="matchMenu(/finance/)"
            to="/expense/billList"
            name="/expense/billList"
          >账单管理</MenuItem>
          <MenuItem
            v-if="matchMenu(/flow/)"
            to="/expense/flowList"
            name="/expense/flowList"
          >收支明细</MenuItem>
        </Submenu> -->
        <Submenu name="setUp"
          v-if="matchMenu(/info|brand|log/)">
          <template slot="title">
            <span class="iconfont iconqiyeguanli"></span>
            企业设置
          </template>
          <MenuItem v-if="matchMenu(/info/)"
            to="/setUp/information"
            name="/setUp/information">企业信息</MenuItem>
          <MenuItem v-if="matchMenu(/brand/)"
            to="/setUp/brand"
            name="/setUp/brand">品牌管理</MenuItem>
          <MenuItem v-if="matchMenu(/log/)"
            to="/setUp/journal"
            name="/setUp/journal">操作日志</MenuItem>
        </Submenu>

      </Menu>
    </Sider>
    <Layout>
      <Header>
        <div style="margin-right:2rem">
          <div>
            <a :href="version"
              class="go_workbench font-size-14">
              <i class="iconfont iconcomputer"
                style="font-size:13px"></i>
              <span class=""
                style="font-weight:500">前往工作台</span>
            </a>
          </div>
        </div>
        <div>
          <Tooltip theme="light">
            <div slot="content">
              <div class="signout"
                @click="signOut">退出登录</div>
            </div>
            <div class="userInfo">
              <img :src="getUserInfo.uImg"
                alt="kong" />
              <span class="userName"
                v-text="getUserInfo.uName"></span>
            </div>
          </Tooltip>
        </div>
      </Header>

      <Content>
        <router-view> </router-view>
      </Content>
    </Layout>
  </Layout>
</template>
<script>
import request from "@request/api/index";
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {},
  data() {
    return {
      activeName: "",
      openName: [],
      menuList: [
        { name1: "首页", id: 1 },
        {
          name2: "项目管理",
          id: 2,
          children: [
            { name1: "项目列表", id: 2 - 1 },
            { name2: "拍摄列表", id: 2 - 2 },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userMenuList: "getCurrentUserMenuList",
      getUserInfo: "getUserInfo",
    }),
    version() {
      const version = this.$store.state.user.userInfo.version || "v20";
      return `/${version}/`;
    },
  },
  created() {
    this.openName.push(this.$route.path.split("/")[1]);
    this.activeName = this.$route.path;
  },
  methods: {
    matchMenu(reg) {
      let regres = !!String(this.userMenuList).match(reg);
      return regres;
    },
    //退出登录接口
    signOut() {
      request.login.requestLogout().then((res) => {
        const { code } = res.data;
        if (code === 1000) {
          window.location.href = "/v20/#/login";
        }
      });
    },
  },
};
</script>
<style lang="scss">
.layout {
  height: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  .logo {
    height: 64px;
    line-height: 64px;
    // text-align: center;
    padding-left: 44px;
    background: #ffffff;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3468fe;
    span {
      display: inline-block;
      margin-right: 14px;
      color: #3468fe;
      font-size: 24px;
    }
  }
  .ivu-menu {
    // box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .ivu-layout-sider {
    background-color: #fff;
  }
  .ivu-layout-header {
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: flex-end;
    // position: fixed;
    z-index: 99;
    .userInfo {
      // width: 21%;
      align-self: center;
      justify-content: center;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        vertical-align: middle;
      }
      .userName {
        padding-left: 10px;
      }
    }
  }
  .ivu-layout {
    background: #f8f9fb;
  }
  .ivu-layout-content {
    padding: 0px 35px 35px 35px;
    min-height: 900px;
    min-width: 1400px;
  }
}
.iconfont {
  font-size: 22px;
}
.iconfeiyongzhongxin {
  font-size: 24px;
}
.iconqiyeguanli {
  font-size: 24px;
}
.iconLOGO {
  line-height: 64px;
  font-size: 32px;
  vertical-align: bottom;
}
.go_workbench {
  color: #515a6e;
  vertical-align: baseline;
  &:hover {
    color: #3468fe;
  }
  > span {
    padding-left: 5px;
  }
}

.el-tooltip__popper.is-dark {
  background-color: #fff;
  color: #000;
}
.signout {
  cursor: pointer;
}
.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu-title {
  font-size: 16px;
}
.ivu-menu-vertical.ivu-menu-light:after {
  background: none;
}
</style>
